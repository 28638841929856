import { Theme } from '@/types'
import { tv, VariantProps } from '@/utils/tv'

export const cardColors: Record<Theme, string[]> = {
  light: ['bg-white', 'bg-gray-5', 'bg-gray-10', 'bg-gray-20'],
  dark: ['bg-gray-900', 'bg-gray-700', 'bg-gray-500', 'bg-gray-400']
}

export const cardContentStickyScroll = tv({
  slots: {
    base: ['relative', 'w-full h-full', 'py-12'],
    wrapper: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8']
  },
  variants: {
    theme: {
      dark: {
        base: 'bg-black'
      },
      light: {
        base: 'bg-gray-10'
      }
    }
  },
  defaultVariants: {
    theme: 'light'
  }
})

export const cardContentStickyScrollNav = tv({
  slots: {
    base: [
      'hidden lg:block',
      'mb-8',
      'sticky top-[calc(40px_+_var(--cigar-bar-height))]'
    ],
    list: ['flex justify-center gap-4'],
    bubble: [
      'absolute left-0 bottom-0',
      'h-12 border rounded-[50vh]',
      'origin-[left_center]'
    ],
    anchor: [
      'flex justify-center items-center',
      'font-medium',
      'h-12 px-5',
      'text-display-12 lg:text-display-14',
      'whitespace-nowrap'
    ]
  },
  variants: {
    theme: {
      dark: {
        bubble: 'border-white',
        anchor: 'text-white'
      },
      light: {
        bubble: '',
        anchor: ''
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type CardContentStickyScrollVariantProps = VariantProps<
  typeof cardContentStickyScroll
>
