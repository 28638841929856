import { CSSProperties, useMemo } from 'react'
import { BlockProps, SliderCard } from '@/types'
import { SliderCardVariantProps, sliderCard } from './slider-card.variants'
import { getMediaType } from '@/utils/media'

export type useSliderCardProps = BlockProps<
  SliderCard,
  SliderCardVariantProps & {
    cardStyle?: CSSProperties
    appearance?: 'default' | 'pro'
  }
>

export const useSliderCard = (props: useSliderCardProps) => {
  const {
    className = '',
    sectionName,
    fixed = false,
    appearance = 'default',
    data: {
      theme = 'dark',
      background,
      backgroundMobile,
      backgroundSize,
      ...restData
    }
  } = props

  const backgroundType = getMediaType(background?.url)

  const styles = useMemo(
    () =>
      sliderCard({
        theme,
        background: backgroundSize,
        type: backgroundType,
        appearance,
        fixed
      }),
    [theme, backgroundSize, backgroundType, appearance, fixed]
  )

  const hasMedia = !!background || !!backgroundMobile

  return {
    theme,
    className,
    sectionName,
    hasMedia,
    backgroundType,
    data: {
      ...restData,
      background,
      backgroundMobile,
      backgroundSize
    },
    styles
  }
}
