import { useMemo } from 'react'
import { BlockProps, Media } from '@/types'
import { QuoteCardVariantProps, quoteCard } from './quote-card.variants'

export type QuoteCard = {
  id: number
  name: string
  role: string
  description: string | null
  avatar: Media
}

export type useQuoteCardProps = BlockProps<QuoteCard, QuoteCardVariantProps> & {
  cardAppearance: 'card' | 'ghost' | 'cardImage' | 'pro'
}

export const useQuoteCard = (props: useQuoteCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    cardAppearance = 'card',
    data: { avatar, ...restData }
  } = props

  const styles = useMemo(
    () => quoteCard({ theme, cardAppearance }),
    [theme, cardAppearance]
  )

  return {
    theme,
    className,
    sectionName,
    cardAppearance,
    data: {
      avatar,
      ...restData
    },
    styles
  }
}
