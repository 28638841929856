import NextImage from 'next/image'
import { Text } from '../text'
import { useQuoteCard, useQuoteCardProps } from './use-quote-card'

export type QuoteCardProps = useQuoteCardProps

const QuoteCard = (props: QuoteCardProps) => {
  const {
    className,
    styles,
    cardAppearance,
    data: { name, role, description, avatar }
  } = useQuoteCard(props)

  const isCardImage = cardAppearance === 'cardImage'

  return (
    <article
      data-component-name="quote-card"
      className={styles.base({ className })}
    >
      <NextImage
        fill={isCardImage}
        width={0}
        height={0}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        src={avatar.url}
        alt={avatar.alternativeText}
        className={styles.bgImage()}
      />
      <div className={styles.wrapper()}>
        <div className={styles.body()}>
          <Text size="h5" className={styles.description()}>
            {description}
          </Text>
        </div>
        <footer className={styles.footer()}>
          <div className={styles.footerDetails()}>
            <Text tag="cite" className={styles.name()} size="h6">
              {name}
            </Text>
            {!!role && (
              <Text tag="span" size="tag" className={styles.role()}>
                {role}
              </Text>
            )}
          </div>
        </footer>
      </div>
    </article>
  )
}

export default QuoteCard
