import { tv, VariantProps } from '@/utils/tv'

export const quoteCard = tv({
  slots: {
    base: ['flex flex-col', 'rounded-[3rem] lg:h-[800px]'],
    wrapper: ['z-10', 'w-full h-full', 'flex flex-col', 'p-[1.4rem] lg:p-10'],
    body: ['flex flex-col gap-5'],
    footer: ['flex gap-5', 'flex-col'],
    footerDetails: ['flex flex-col gap-1 h-full'],
    name: ['not-italic'],
    role: [],
    bgImage: ['w-full h-full object-center object-cover', 'rounded-[3rem]'],
    description: ['xl:text-display-28']
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        role: 'text-gray-20'
      },
      light: {
        base: 'text-black',
        role: 'text-gray-60'
      }
    },
    cardAppearance: {
      card: [],
      ghost: [],
      cardImage: {
        base: ['h-full'],
        wrapper: [
          'justify-end',
          'bg-gradient-to-t from-black to-transparent to-[120%]'
        ],
        footer: ['mt-[1.4rem] lg:mt-[2.5rem]']
      },
      pro: {
        base: ['border border-gray-600', 'pb-4'],
        wrapper: ['justify-start'],
        footer: ['mt-[3rem] lg:mt-auto'],
        footerDetails: ['justify-items-end']
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type QuoteCardVariantProps = VariantProps<typeof quoteCard>
