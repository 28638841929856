import { tv, VariantProps } from '@/utils/tv'

export const iconCard = tv({
  slots: {
    base: ['flex flex-col', 'w-full h-full', 'overflow-hidden'],
    header: ['h-full'],
    icon: ['flex', 'h-10 w-10'],
    title: [],
    description: [],
    footer: []
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        icon: 'text-acqua-500',
        description: 'text-gray-40'
      },
      light: {
        base: 'text-black',
        icon: 'text-acqua-900',
        description: 'text-gray-60'
      }
    },
    appearance: {
      ghost: {
        base: '',
        footer: 'mt-6'
      },
      card: {
        base: 'drop-shadow-md rounded-3xl',
        header: 'p-6',
        footer: 'p-6'
      },
      inline: {
        base: [
          'py-8 lg:py-14',
          'lg:flex-row',
          'gap-6',
          'border-t border-white/10'
        ],
        header: ['flex flex-col lg:flex-row gap-3 lg:gap-6'],
        title: ['lg:basis-[40%]', 'shrink-0', 'xl:!text-display-32'],
        description: ['flex-wrap grow-0', 'text-gray-60']
      }
    },
    iconColor: {
      acqua: {},
      white: {
        icon: ['text-white']
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    appearance: 'ghost'
  },
  compoundVariants: [
    {
      theme: 'dark',
      appearance: 'card',
      className: {
        header: 'bg-gray-800',
        footer: 'bg-gray-600'
      }
    },
    {
      theme: 'light',
      appearance: 'card',
      className: {
        header: 'bg-gray-5',
        footer: 'bg-gray-new-5'
      }
    },
    {
      appearance: ['card', 'ghost'],
      className: {
        icon: ['mb-4'],
        description: ['mt-3']
      }
    }
  ]
})

export type IconCardVariantProps = VariantProps<typeof iconCard>
