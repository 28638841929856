'use client'

import { Button } from '../button'
import { Icon } from '../icon'
import { Text } from '../text'
import { useIconCard, useIconCardProps } from './use-icon-card'

export type IconCardProps = useIconCardProps

const IconCard = (props: IconCardProps) => {
  const {
    className,
    sectionName,
    styles,
    theme,
    data: { icon, title, description, link },
    buttonColor,
    isAppearanceInline
  } = useIconCard(props)

  const titleSize = isAppearanceInline ? 'h4' : 'subtitle'
  const descriptionSize = isAppearanceInline ? 'h6' : undefined

  return (
    <article
      data-component-name="icon-card"
      className={styles.base({ className })}
    >
      <header className={styles.header()}>
        {!!icon && <Icon name={icon?.name} className={styles.icon()} />}
        {!!title && (
          <Text
            tag="h3"
            size={titleSize}
            theme={theme}
            className={styles.title()}
            weight="medium"
          >
            {title}
          </Text>
        )}
        {!!description && (
          <Text
            size={descriptionSize}
            theme={theme}
            className={styles.description()}
            weight="medium"
          >
            {description}
          </Text>
        )}
      </header>
      {!!link && (
        <footer className={styles.footer()}>
          <Button
            sectionName={`${sectionName}_${title}`}
            href={link.url}
            title={link.text}
            newTab={link.newTab}
            appearance={isAppearanceInline ? 'outline' : 'text'}
            color={isAppearanceInline ? 'gray' : buttonColor}
            leftIcon={isAppearanceInline ? 'arrow_right' : undefined}
            rounded={isAppearanceInline}
          >
            {isAppearanceInline ? undefined : link.text}
          </Button>
        </footer>
      )}
    </article>
  )
}

export default IconCard
